<template>
  <div class="works">
    <LeftMenu current="songManage"></LeftMenu>
    <div class="main" :class="{ updateApplyList: $route.meta.active }">
      <div class="manage">
        <div class="content">
          <div class="backBox">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item to="/applyList">歌曲文件</el-breadcrumb-item>
              <el-breadcrumb-item>查看</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-divider></el-divider>
          <el-descriptions labelClassName="title"  contentClassName="content" :colon="false" class="margin-top" :column="2">
            <el-descriptions-item label="歌曲名称">{{details.song_name}}</el-descriptions-item>
            <el-descriptions-item label="提交人">{{details.submit_name}}</el-descriptions-item>
            <el-descriptions-item label="艺人名称">{{details.singer}}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{details.created_at}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{detailsList.length > 0 ? '已上传' :'未上传'}}</el-descriptions-item>
            <el-descriptions-item label="歌曲版本">{{details.version_note ? details.version_note : '/'}}</el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 30px;min-height: 300px;">
            <el-table style="font-size: 13px;" v-loading="tableLoad" :data="detailsList"  header-row-class-name="table-header">
              <el-table-column label="文件名" prop="name"></el-table-column>
              <el-table-column width="150" label="文件大小">
                <template v-slot:="{row}">
                  {{byteSizeFn(row.size_byte)}}
                </template>
              </el-table-column>
              <el-table-column label="文件类型">
                <template v-slot:="{row}">
                  <div v-for="(i,index) in (row.ptf_types.map(item =>item.type.remark != null ? `${item.type.type_name}(${item.type.remark})`:`${item.type.type_name}`))" :key="index">
                    {{i}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="90px"></el-table-column>
              <el-table-column label="上传时间" prop="created_at"></el-table-column>
              <el-table-column label="上传用户" prop="upload_user">
                <template v-slot="{row}">
                  <div>{{row.upload_user}}</div>
                  <div>{{row.upload_ip}}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <router-link target="_blank"  :to="'/share-musician/'+ songId ">
            <el-button type="primary" style="width: 140px;" >上传</el-button>
            </router-link>
            <share-link :id="Number(songId)">
              <el-button style="margin: 0 20px;width:140px;font-size: 14px;font-family: PingFang SC, PingFang SC-Medium;font-weight: 500;color:#ff004d;border: 0.5px solid #ff004d;border-radius: 3px;">分享上传链接</el-button>
            </share-link>
            <el-button style="width:140px" @click="$router.back()">关闭</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import byteSize from 'byte-size';
import LeftMenu from "../../components/LeftMenu";
import ShareLink from "@/views/issue/components/shareLink/index.vue";
export default {
  name: "songFileDetails",
  props:{
    songId:String,
  },
  components:{
    ShareLink,
    LeftMenu
  },
  computed:{
    byteSizeFn(){
      return val => byteSize(val)
    }
  },
  data(){
    return {
      details:{
        project:{}
      },
      detailsList:[],
      tableLoad:false,
    }
  },
  created() {
    this.getDetails()
    this.getDetailsList()
  },
  methods:{
    getDetails(){
      this.$get('/api/property/universal/track/show?track_id=' + this.songId).then(r => {
        this.details = r.data
      })
    },
    getDetailsList(){
      this.tableLoad = true;
      this.$get('/api/property/universal/track/file?pageSize=1000&track_id=' + this.songId).then(r => {
        this.tableLoad = false;
        this.detailsList = r.data.list
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works";
.content{
  ::v-deep .table-header{
     .el-table__cell{
       background:#f7f7f7;
       font-size: 14px;
       font-family: PingFang SC-Regular;
       font-weight: 400;
       color: #333333;
     }
  }
  ::v-deep .title{
    padding-bottom: 10px;
    width: 78px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #828282;
  }
  ::v-deep .content{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;

  }
  .footer{
    text-align:center;
     margin-top: 40px;
  }
}

</style>
